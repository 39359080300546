import Vue from 'vue'
import App from './App.vue'
import VueMaterial from 'vue-material'
import { createPinia } from 'pinia'
import { useDatabaseStore } from "./store/firebase";

import './main.scss'

Vue.config.productionTip = false;

Vue.use(VueMaterial);
Vue.use(createPinia());

new Vue({
  render: h => h(App),
}).$mount('#app')


window.$database = useDatabaseStore();
