<template>
  <div id="app">
    <HomePage
      v-if="eventToken && eventToken.length >= 4 && invitationToken && invitationToken.length >= 4"
      :eventToken="eventToken.toLowerCase()"
      :invitationToken="invitationToken.toLowerCase()"
    />
    <div v-else style="margin: auto;max-width: 640px;">
      <md-card style="margin: 60px;" class="invitation-card">
        <md-card-header>
          <div class="md-title" style="color: #ebb640;">Einladung bestätigen</div>
        </md-card-header>

        <md-card-content>
          Trage bitte die in der Einladung genannte Event- und Personal-ID ein.<br/>
          Danach kannst Du Dich im Gästebuch mit Deinen Wunschterminen eintragen.<br/>
          <br/>

          <md-field>
            <label>Event-ID</label>
            <md-input v-model="eventToken"></md-input>
          </md-field>

          <md-field>
            <label>Personal-ID</label>
            <md-input v-model="invitationToken"></md-input>
          </md-field>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import HomePage from './pages/HomePage.vue'

export default {
  name: 'App',
  components: {
    HomePage
  },

  mounted() { 
    const urlParams = new URLSearchParams(window.location.search);

    this.eventToken = urlParams.get('event');
    this.invitationToken = urlParams.get('invitation');
  },
 
  data() {   
    return {
      eventToken: '',
      invitationToken: ''
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ebb640;
}

.invitation-card * {
  font-family: 'Quattrocento', serif;
}

.md-focused .md-input,
.md-focused label {
  color: #ebb640 !important;
}

.md-field.md-theme-default.md-has-textarea:not(.md-autogrow):before {
  border-color: #ebb640 !important;
}

.md-field.md-theme-default.md-has-textarea:not(.md-autogrow):after {
  border-color: rgba(255, 255, 255, 0.8) !important;
}

.md-focused.md-field.md-theme-default:before {
  background-color: #ebb640;
}
</style>
