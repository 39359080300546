<template>
  <div v-if="event && event.guestbook_entries && event.guestbook_entries[this.invitationToken]">
    <md-tabs v-if="event.general.enable_navigation" class="md-primary sk-navbar" md-alignment="centered" :md-active-tab="activeId">
      <md-tab
          v-if="event.general.welcome.enable"
          @click="scrollTo('area-welcome')"
          id="sk-navbar__area-welcome"
          :md-label="event.general.welcome.content.menu_name"
          :md-icon="event.general.welcome.content.menu_icon"
      ></md-tab>
      <md-tab
          v-if="event.general.collection.enable"
          @click="scrollTo('area-collection')"
          id="sk-navbar__area-collection"
          :md-label="event.general.collection.content.menu_name"
          :md-icon="event.general.collection.content.menu_icon"
      ></md-tab>
      <md-tab
          v-if="event.general.participation.enable"
          @click="scrollTo('area-participation')"
          id="sk-navbar__area-participation"
          :md-label="event.general.participation.content.menu_name"
          :md-icon="event.general.participation.content.menu_icon"
      ></md-tab>
      <md-tab
          v-if="event.general.guestbook.enable"
          @click="scrollTo('area-guestbook')"
          id="sk-navbar__area-guestbook"
          :md-label="event.general.guestbook.content.menu_name"
          :md-icon="event.general.guestbook.content.menu_icon"
      ></md-tab>
      <md-tab
          v-if="event.general.location.enable"
          @click="scrollTo('area-location')"
          id="sk-navbar__area-location"
          :md-label="event.general.location.content.menu_name"
          :md-icon="event.general.location.content.menu_icon"
      ></md-tab>
    </md-tabs>

    <div v-if="event.general.welcome.enable" id="area-welcome" :style="'background-image: url(' + event.general.welcome.content.head_image + ')'">
      <md-card class="welcome-card">
        <md-card-header>
          <div class="md-title">{{ event.general.welcome.content.title }}</div>
        </md-card-header>

        <md-card-content>
          {{ event.general.welcome.content.text }}
        </md-card-content>
      </md-card>
    </div>

    <div v-if="event.general.collection.enable" id="area-collection" class="container">
      <h2>{{ event.general.collection.content.title }}</h2>

      <div class="area-collection__items">
        <md-card class="collection-card" v-for="(item, index) in event.collection" :key="index">
          <md-card-media-cover md-solid>
            <div class="collection-card__actions">
              <md-button
                @click="openLink(item.infoLink);"
                class="md-icon-button"
              >
                <md-icon>info</md-icon>
              </md-button>

              <md-button
                v-if="event.general.participation.enable || !item.active"
                :disabled="!item.active"
                :class="{
                  'collection-card-actions__noted': true,
                  'md-icon-button': true,
                  'collection-card-actions-noted__active': formData.preferredItems[item.name],
                  'collection-card-actions-noted__disabled': item.active == false,
                  }"
                @click="item.active ? $set(formData.preferredItems, item.name , !formData.preferredItems[item.name]) : false;"
              >
                <md-icon v-if="item.active">favorite</md-icon>
                <md-icon v-else>lock</md-icon>
              </md-button>
            </div>

            <md-card-media> 
              <img :src="item.image" :alt="item.name">
            </md-card-media>

            <md-card-area>
              <md-card-header class="collection-card__texts">
                <span class="md-title">{{ item.name }}</span>
                <span class="md-subline" v-if="item.text">
                  {{ item.text }}
                </span>
                <span class="md-subline" v-else>
                  <template v-if="item.age">{{ item.age }}</template>
                  <template v-if="item.age && item.alcoholContent"> / </template>
                  <template v-if="item.alcoholContent">{{ item.alcoholContent }}</template>
                </span>
              </md-card-header>
            </md-card-area>
          </md-card-media-cover>
        </md-card>
      </div> 
      <br/>
      <span v-if="event.general.participation.enable">*Nutze das Herz-Icon, damit der Gastgeber Deine Auswahl vorbereiten kann</span>
    </div>

    <div v-if="event.general.participation.enable" id="area-participation" class="bg-blue">
      <div class="container">
        <h2>{{ event.general.participation.content.title }}</h2>

        <div class="copy" v-html="event.general.participation.content.text"></div>

        <br/>
        <br/>

        <div class="form">
          <div style="max-width: 420px;margin: 0 auto;">
            <md-checkbox
              v-for="(formattedDate, date) in event.dates" :key="date"
              v-model="formData.selectedDates[date]"
              value="1"
            >{{ formattedDate }}</md-checkbox>
          </div>

          <br/>

          <md-field>
            <label>Dein Name</label>
            <md-input v-model="formData.name"></md-input>
          </md-field>
        
          <md-field>
            <label>Deine Nachricht für das Gästebuch</label>
            <md-textarea v-model="formData.message"></md-textarea>
          </md-field>
          <br/>
          
          <img v-if="formData.imageBlob.length > 0" :src="formData.imageBlob" />

          <md-field>
            <label>Du</label>
            <md-file
              v-model="formData.rawImage"
              placeholder="Wenn Du magst, lade ein Bild von Dir hoch"
              accept="image/*"
              @md-change="setImageBlob"
            />
          </md-field>

          <span>*Diese Daten tauchen im Gästebuch auf</span>
          <br/>
          <md-button
            class="md-dense md-raised md-primary"
            @click="saveGuestbookEntry"
          >Senden</md-button>

          <md-snackbar md-position="center" :md-duration="8000" :md-active.sync="showFailedSnackbar" md-persistent>
            <span>
              <template v-if="formData.name.length == 0">Bitte gebe deinen Namen an</template>
              <template v-if="formData.name.length == 0 && Object.keys(selectedDatesFormatted).length == 0"><br/><br/></template>
              <template v-if="Object.keys(selectedDatesFormatted).length == 0">Bitte wähle mindestens einen Termin an dem Du kannst</template>
            </span>
          </md-snackbar>

          <md-snackbar md-position="center" :md-duration="8000" :md-active.sync="showSuccessSnackbar" md-persistent>
            <span>Du hast Dich nun im Gästebuch eingetragen. Der Gastgeber wird sich melden, sobald ein Termin feststeht.</span>
          </md-snackbar>
        </div>
      </div>
    </div>
    
    <div v-if="event.general.guestbook.enable" id="area-guestbook">
      <div class="container">
        <h2>{{ event.general.guestbook.content.title }}</h2>

        <div class="guestbook-entries">
          <template
            v-for="(guestbookEntry, uuid) in event.guestbook_entries"
          >
            <md-card
              :key="uuid"
              v-if="guestbookEntry.name.length > 0"
              class="guestbook-entries__entry md-theme-default"
            >
              <md-card-media>
                <div :class="'guestbook-entries-entry__media ' + getGuestbookEntryImageClass(guestbookEntry)">
                  <img
                    :src="guestbookEntry.image ? guestbookEntry.image : '/static/Guestbook_Fallback.jpg'"
                    :alt="guestbookEntry.name"
                  />
                </div>
              </md-card-media>

              <md-card-header>
                <div class="md-title">{{ guestbookEntry.name }}</div>
                <div class="md-subhead">{{ guestbookEntry.message }}</div>
              </md-card-header>
            </md-card>
          </template>
        </div>
      </div>
    </div>
    
    <div v-if="event.general.location.enable" id="area-location" class="bg-blue">
      <div class="container">
        <h2>{{ event.general.location.content.title }}</h2>

        <div class="location">
          <div class="copy" style="text-align:left;" v-html="event.general.location.content.text"></div>

          <div class="maps">
            <img :src="event.general.location.content.image" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else style="margin: auto;max-width: 640px;">
    <md-card style="margin: 60px;">
      <md-card-header>
        <div class="md-title" style="color: #ebb640;">Keine gültige Einladung</div>
      </md-card-header>

      <md-card-content>
        Die Einladung ist leider ungültig. Bitte suche den Kontakt mit dem Veranstalter auf!<br/>
        <br/>
        <md-button
            class="md-dense md-raised md-primary"
            @click="reload()"
        >Erneut versuchen</md-button>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import Compressor from 'compressorjs';

export default { 
  name: 'HomePage',

  mounted() {
    window.$database.observeGuestbookEntries(this.eventToken);

    const params = new URLSearchParams(window.location.search);

    if (!params.has('event')) {
      params.append('event', this.eventToken);
    } else {
      params.set('event', this.eventToken);
    }

    if (!params.has('invitation')) {
      params.append('invitation', this.invitationToken);
    } else {
      params.set('invitation', this.invitationToken);
    }

    window.history.replaceState({}, '', `${window.location.pathname}?${params}`);

    window.$database.getEvent(this.eventToken).then((event) => {
      if (event) {
        this.event = event;

        if (event.guestbook_entries[this.invitationToken]) {
          let invitationData = event.guestbook_entries[this.invitationToken];

          this.formData = {
            name: invitationData.name ?? '',
            message: invitationData.message ?? '',
            imageBlob: invitationData.image ?? '',
            selectedDates: invitationData.selectedDates ?? {},
            preferredItems: invitationData.preferredItems ?? {}
          };
        }

        window.document.title = event.general.meta.title;
        window.document.querySelector('meta[property="og:title"]').content = event.general.meta.title;
        window.document.querySelector('meta[property="og:description"]').content = event.general.meta.text;
        window.document.querySelector('meta[property="og:image"]').content = event.general.meta.image;
      }
    });

    window.addEventListener('scroll', this.updateActiveId);
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.updateActiveId);
  },

  computed: {
    activeMenuTab() {
      return "area-collection";
    },

    guestbookEntries() {
      return window.$database.guestbookEntries;
    },

    selectedDatesFormatted() {
      let selectedDates = {};

      Object.keys(this.formData.selectedDates).forEach((key) => {
        if (this.formData.selectedDates[key] == 1) {
          selectedDates[key] = true;
        }
      });

      return selectedDates;
    }
  },

  methods: {
    reload() {
      window.history.replaceState({}, '', window.location.pathname);
      window.location.reload();
    },
    openLink(link) {
      window.open(link, '_blank')
    },
    scrollTo(id) {
      this.activeId = 'sk-navbar__' + id;
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: 'smooth' });
    },

    updateActiveId() {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        this.activeId = 'sk-navbar__area-location';
      } else {
        const yPositions = this.sections.map(id => {
          return document.getElementById(id).getBoundingClientRect().top - 74;
        });
        const activeSectionIndex = yPositions.findIndex(y => y >= 0);
        this.activeId = 'sk-navbar__' + (this.sections[activeSectionIndex - 1] || this.sections[0]);
      }
    },

    getGuestbookEntryImageClass(guestbookEntry) {
      if (!guestbookEntry.selectedDates || guestbookEntry.selectedDates.length <= 0) {
        return 'guestbook-entries-entry__media--invitation-pending';
      }

      if (guestbookEntry.selectedDates['canceled'] && guestbookEntry.selectedDates['canceled'] === '1') {
        return 'guestbook-entries-entry__media--invitation-canceled';
      }

      let hasFeedback = false;

      Object.keys(guestbookEntry.selectedDates).forEach((key) => {
        if (guestbookEntry.selectedDates[key] === '1') {
          hasFeedback = true;
        }
      });

      let isCanceled = false;

      if (Object.keys(this.event.dates).length === 2) {
        Object.keys(this.event.dates).forEach((key) => {
          if (key !== 'canceled' && guestbookEntry.selectedDates[key] !== '1') {
            isCanceled = true;
          }
        });
      }

      if (isCanceled) {
        return 'guestbook-entries-entry__media--invitation-no-match';
      }

      if (hasFeedback) {
        return '';
      }

      return 'guestbook-entries-entry__media--invitation-pending';
    },

    setImageBlob(files) {
      let me = this;

      new Compressor(files.item(0), {
        quality: 0.9,
        resize: "cover",
        width: 260,
        height: 260,
        success(result) {
            let reader = new FileReader();

            reader.readAsDataURL(result);

            reader.onload = function() {
              me.formData.imageBlob = reader.result;
            }
          }
      });
    },

    saveGuestbookEntry() {
      if (this.formData.name.length == 0 || Object.keys(this.selectedDatesFormatted).length == 0) {
        this.showFailedSnackbar = true;

        return;
      }

      this.showSuccessSnackbar = true;

      window.$database.pushGuestbookEntry({
        eventToken: this.eventToken,
        invitationToken: this.invitationToken,
        name: this.formData.name,
        message: this.formData.message,
        image: this.formData.imageBlob,
        selectedDates: this.formData.selectedDates,
        preferredItems: this.formData.preferredItems,
      });
    }
  },
  
  props: {
    eventToken: String,
    invitationToken: String
  },

  data() {   
    return {
      event: {},
      activeId: 'sk-navbar__area-welcome',
      sections: [
        'area-welcome',
        'area-collection',
        'area-participation',
        'area-guestbook',
        'area-location'
      ],
      showFailedSnackbar: false,
      showSuccessSnackbar: false,
      formData: {
        name: '',
        message: '',
        imageBlob: '',
        selectedDates: {},
        preferredItems: {}
      }
    }
  }
}
</script>

<style scoped>

/* Primärfaben */
h1, h2, h3, .md-title {
  color: #ebb640 !important;
  text-shadow: #524921 1px 0 10px;
  font-family: 'Quattrocento', serif;
}
/* Sekundärfarben */
span, p, .md-card-content, .copy, .md-checkbox-label, .md-checkbox {
  color: rgba(255, 255, 255, 0.8) !important;
  font-family: 'Quattrocento', serif;
}

.md-checkbox {
  text-align: left;
}

.container {
  max-width: 996px;
  margin: 0 auto;
}

.bg-blue {
  width: 100%;
  background-color: #284764;
  padding: 10px 0 30px;
  margin-top: 80px;
}

.sk-navbar {
  position: fixed;
  width: 100%;
  z-index: 999999;
}

#area-welcome {
  background-image: url("../assets/banner/banner-01.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 60px;
  height: 100vh;

  display: flex;
  align-items: center;
}
 
.welcome-card {
  margin-left: auto;
  background: rgba(39, 71, 101, 0.90) !important;
}

.welcome-card .md-title {
  font-weight: 700;
  font-size: 22px;
}

.welcome-card .md-card-content {
  max-width: 80%;
  margin: 0 auto;
  font-size: 16px;
}

.collection-card-actions-noted__disabled i {
  color: #7d8a83 !important
}

.collection-card-actions-noted__active i {
  color: red !important;
}

#area-collection {
  min-height: 100vh;
}

.area-collection__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 18px;
}

.collection-card {
    width: 320px;
    display: inline-block;
    vertical-align: top;

}
.collection-card .md-card-area {
  height: 125px;
}

.collection-card .md-card-header {
  padding: 12px;
  margin: 0;
  height: 125px;
}

.collection-card__actions {
  position: absolute;
  right: 0;
  margin-top: 6px;
}

.collection-card__texts {
  display: flex;
  justify-items: stretch;
}

.collection-card__texts .md-title {
  flex-grow: 1;
}

.collection-card__texts * {
  font-family: 'Eczar', serif !important;
}

.container h2 {
  font-size: 28px;
  margin: 45px 0 40px 0;
}

.copy {
  font-size: 16px;
  line-height: 26px;
}

.form *,
.md-has-textarea * {
  font-family: 'Quattrocento', serif !important;
}

.md-checkbox {
  width: 140px !important;
}

.md-checkbox {
  margin-left: 45px;
}

.md-focused .md-input,
.md-focused label {
  color: #ebb640 !important;
}

.md-field.md-theme-default.md-has-textarea:not(.md-autogrow):before {
  border-color: #ebb640 !important;
}

.md-field.md-theme-default.md-has-textarea:not(.md-autogrow):after {
  border-color: rgba(255, 255, 255, 0.8) !important;
}

.md-focused.md-field.md-theme-default:before {
  background-color: #ebb640;
}
.guestbook-entries {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 18px;
}
.guestbook-entries__entry {
  max-width: 260px;
}

.md-snackbar.md-theme-default {
  background-color: #424242 !important;
}

.guestbook-entries-entry__media--invitation-pending::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/Guestbook_Feedback_Pending.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.guestbook-entries-entry__media--invitation-canceled::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/Guestbook_Canceled.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}


.guestbook-entries-entry__media--invitation-no-match::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/Guestbook_Wrong_Date.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}


@media only screen and (max-width: 1029px) {
  #area-participation .container {
    padding: 18px;
  }
  
  #area-participation .container .md-checkbox {
    margin-left: 30px;
  }

  .location {
    padding: 18px;
  }

  .location .copy {
    text-align: center !important;
    padding-bottom: 45px;
  }
}

@media only screen and (min-width: 1030px) {
  .location {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
