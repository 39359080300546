import {ref, set, onValue, get} from "firebase/database";
import {database} from "@/core/firebase";
import {defineStore} from 'pinia';

export const useDatabaseStore = defineStore('DatabaseStore', {
    state: () => ({
        event: {
            "general": {
                "welcome": {
                    "enable": true,
                    "content": {
                        "menu_name": "Willkommen",
                        "menu_icon": "emoji_people",
                        "title": "Einladung zum Whisky-Tasting",
                        "text": "Du wurdest von Sebastian zu einem geselligem Whisky-Tasting-Abend unter Freunden eingeladen.",
                        "head_image": "/img/banner-01.efd79400.jpg"
                    }
                },
                "collection": {
                    "enable": true,
                    "content": {
                        "menu_name": "Whiskys",
                        "menu_icon": "wine_bar",
                        "title": "Einladung zum Whisky-Tasting",
                        "text": "Du wurdest von Sebastian zu einem geselligem Whisky-Tasting-Abend unter Freunden eingeladen."
                    }
                },
                "participation": {
                    "enable": true,
                    "content": {
                        "menu_name": "Termin",
                        "menu_icon": "calendar_month",
                        "title": "Teilnahme",
                        "text": "Du hast in der Auswahl etwas gefunden was zu dir passt oder du möchstest einfach nur so teilnehmen? Dann teile mir bitte mit, wer du bist und wann du Zeit hast.<br/><br/>Ich versuche einen Termin zu finden an dem möglichst alle Zeit haben. Sobald ich genug Antworten vorliegen habe melden ich mich. Der Beginn des Events ist für den frühen Abend anvisiert.<br/><br/>Vorort darfst du gerne eine freiwillige Spende hinterlassen, damit die Sammlung um das gekostete rehabilitiert werden kann."
                    }
                },
                "guestbook": {
                    "enable": true,
                    "content": {
                        "menu_name": "Gäste",
                        "menu_icon": "groups",
                        "title": "Die Gäste stellen sich vor"
                    }
                },
                "location": {
                    "enable": true,
                    "content": {
                        "menu_name": "Standort",
                        "menu_icon": "near_me",
                        "title": "Standort",
                        "text": "Sebastian Koch<br/>Marxstr. 10D<br/>45527 Hattingen Welper",
                        "image": "/static/Parking.jpg"
                    }
                }
            },
            "dates": {},
            "collection": {},
            "guestbook_entries": {}
        }
    }),

    actions: {
        async getEvent(eventToken) {
            let snapshot = await get(ref(database, '/events/' + eventToken));

            if (snapshot.exists()) {
                let event = snapshot.val();
                
                this.event = {
                    "general": event.general,
                    "dates": event.dates,
                    "collection": event.collection,
                    "guestbook_entries": {}
                };

                Object.keys(event.guestbook_entries).forEach(key => {
                    if (event.guestbook_entries[key].internalNote !== 'free') {
                        this.event.guestbook_entries[key] = event.guestbook_entries[key];
                    }
                });

                return this.event;
            }

            return undefined;
        },

        observeGuestbookEntries(eventToken) {
            onValue(ref(database, '/events/' + eventToken + '/guestbook_entries/'), (snapshot) => {
                let guestbookEntries = snapshot.val();

                Object.keys(guestbookEntries).forEach(key => {
                    if (guestbookEntries[key].internalNote !== 'free') {
                        this.event.guestbook_entries[key] = guestbookEntries[key];
                    }
                });
            });
        },

        pushGuestbookEntry(guestbookEntry) {
            set(ref(database, '/events/' + guestbookEntry.eventToken + '/guestbook_entries/' + guestbookEntry.invitationToken),
                {
                    "internalNote": this.event.guestbook_entries[guestbookEntry.invitationToken].internalNote,
                    "name": guestbookEntry.name,
                    "message": guestbookEntry.message,
                    "image": guestbookEntry.image,
                    "selectedDates": guestbookEntry.selectedDates,
                    "preferredItems": guestbookEntry.preferredItems,
                }
            );
        }
    }
});
